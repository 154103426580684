/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import BankWiseMIS from "../BankWiseMIS/BankWiseMIS"
// ----------------------------------------------------------------------

export default function ExecutiveWiseMIS(props) {
  return (
    <BankWiseMIS name="Executive Wise MIS" />
  );
}
