/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Link, Stack, Button, Card, TextField, Checkbox, FormControlLabel, Autocomplete  } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';

// components
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

export default function EntryFormPR(props) {

  const navigate = useNavigate()
  const params = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paramsData, setParamsData] = useState([]);
  const [fromData, setFromData] = useState({
    reciptDate: new Date(),
    fileNo: '',
    bank: [],
    branch: [],
    loanACNo: '',
    refNo: [],
    name: [],
    handledByName: [],
    customerBorrower: '',
    payOrderNo: '',
    forRs: '',
    dated: '',
    reciptNo: '',
    amount: '',
    recDate: '',
    remarks: '',
    favoring: '',
    address: '',
    id: '',
    status: true
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    setParamsData(JSON.parse(params.data));
    if (params.data !== '0') {
      setFromData({ 
        name: typeof paramsData.name !== 'undefined' ? paramsData.name : '',
        branch: typeof paramsData.branch !== 'undefined' ? paramsData.branch : '',
        contactPerson: typeof paramsData.contactPerson !== 'undefined' ? paramsData.contactPerson : '',
        address: typeof paramsData.address !== 'undefined' ? paramsData.address : '',
        std: typeof paramsData.std !== 'undefined' ? paramsData.std : '',
        phoneOne: typeof paramsData.phoneOne !== 'undefined' ? paramsData.phoneOne : '',
        phoneTwo: typeof paramsData.phoneTwo !== 'undefined' ? paramsData.phoneTwo : '',
        email: typeof paramsData.email !== 'undefined' ? paramsData.email : '',
        fax: typeof paramsData.fax !== 'undefined' ? paramsData.fax : '',
        website: typeof paramsData.website !== 'undefined' ? paramsData.website : '',
        status: typeof paramsData.status !== 'undefined' ? paramsData.status : true,
        id: typeof paramsData.id !== 'undefined' ? paramsData.id : '',
      });
    }
  }, [params.data, 
      paramsData.name, 
      paramsData.branch, 
      paramsData.contactPerson, 
      paramsData.address, 
      paramsData.std, 
      paramsData.phoneOne, 
      paramsData.phoneTwo, 
      paramsData.email, 
      paramsData.fax, 
      paramsData.website, 
      paramsData.id,
      paramsData.status,
    ]);
  

  const onSubmit = async (event) => {
    event.preventDefault()
    const fromElementsData = event.target.elements
    const sendPost = {
      name: fromElementsData.name.value,
      id: fromData.id,
    }
    
    try {
      console.log(fromData)
      setIsSubmitting(true);
    }
    catch (err) {
      console.log(err)
    }
  };
  
  const onChangeFields = async (event) => {
    if(event.target.name === 'status') {
      setFromData({
        ...fromData,
        [event.target.name]: !fromData.status
      });
    } else {
      setFromData({
        ...fromData,
        [event.target.name]: event.target.value
      });
    }
  };
  
  const redirectPage = async (url) => {
    navigate(`/app/option/${url}`, { replace: true });
  };

  return (
    <Page title="Prepare Reports">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Prepare Reports
          </Typography>
          <Button variant="contained" color="secondary" onClick={() => redirectPage('')} startIcon={<Iconify icon="carbon:list-boxes" />}>
            Home
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button variant="outlined" color="info" onClick={() => redirectPage('prepareReports')} startIcon={<Iconify icon="akar-icons:arrow-back" />}>
            View List
          </Button>
        </Stack>
        <form methods="post" onSubmit={onSubmit}>
        <Card>
          <Grid container alignItems="center" paddingLeft={10} paddingBottom={10} paddingRight={10} paddingTop={5} spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.reciptDate}
                required
                name="reciptDate"
                label="Receipt Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.bankRefNo}
                name="bankRefNo" 
                label="Bank Ref No"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.bank}
                renderInput={(params) => <TextField required name='bank' onChange={onChangeFields} value={fromData.bank} {...params} label="Select Bank" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.bank}
                renderInput={(params) => <TextField required name='branch' onChange={onChangeFields} value={fromData.branch} {...params} label="Select Branch" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.apsNo}
                name="apsNo"  
                label="APS No"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.report}
                renderInput={(params) => <TextField required name='report' onChange={onChangeFields} value={fromData.report} {...params} label="Select Report" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.customerBorrower}
                name="customerBorrower" 
                label="Customer Borrower"
              />
            </Grid>
            
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.repNo}
                name="repNo" 
                label="Rep No"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.profCharges}
                name="profCharges" 
                label="Prof Charges"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.inspectionReceipt}
                name="inspectionReceipt" 
                label="Inspection Receipt"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.outOfPocketExp}
                name="outOfPocketExp" 
                label="Out Of Pocket Exp"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.searchExp}
                name="searchExp" 
                label="Search Exp"
              />
            </Grid>

            <Grid mt={2} mb={2} item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="overline" gutterBottom>
              Property Address <Iconify icon="bi:arrow-down" />
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.flatHousePlotNo}
                name="flatHousePlotNo" 
                label="Flat/House/Plot No"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.roofRight}
                renderInput={(params) => <TextField required name='roofRight' onChange={onChangeFields} value={fromData.roofRight} {...params} label="Select Roof Right" />}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.floor}
                name="floor" 
                label="Floor"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.RepRefNo}
                name="RepRefNo" 
                label="Rep Ref No"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.streetSectorLocal}
                name="streetSectorLocal" 
                label="Street/Sector/Locality"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.referBy}
                name="referBy" 
                label="ReferBy"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.city}
                name="city" 
                label="City"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.reportDate}
                name="reportDate" 
                label="Report Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.phoneNo}
                name="phoneNo" 
                label="Phone No"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.reportSentThru}
                renderInput={(params) => <TextField required name='reportSentThru' onChange={onChangeFields} value={fromData.reportSentThru} {...params} label="Select Report Sent Through" />}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.email}
                name="email" 
                label="Email"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.reportSentOn}
                name="reportSentOn" 
                label="Report Sent On"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.receivedBy}
                name="receivedBy" 
                label="Received By"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.location}
                renderInput={(params) => <TextField required name='location' onChange={onChangeFields} value={fromData.location} {...params} label="Select Location" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.collectedBy}
                renderInput={(params) => <TextField required name='collectedBy' onChange={onChangeFields} value={fromData.collectedBy} {...params} label="Select Collected By" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.preparedBy}
                renderInput={(params) => <TextField required name='preparedBy' onChange={onChangeFields} value={fromData.preparedBy} {...params} label="Select Prepared By" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.nature}
                renderInput={(params) => <TextField required name='nature' onChange={onChangeFields} value={fromData.nature} {...params} label="Select Nature" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fromData.status}
                renderInput={(params) => <TextField required name='status' onChange={onChangeFields} value={fromData.status} {...params} label="Select Status" />}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.remarks}
                name="remarks" 
                label="Remarks"
                multiline
                rows={2}  
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.fallowUp}
                name="fallowUp" 
                label="Fallow UP"
                multiline
                rows={2}  
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                onChange={onChangeFields}
                fullWidth
                value={fromData.fileUpload}
                name="fileUpload" 
                label="file Upload"
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            

            


            <Grid item xs={12} sm={1} md={1} lg={1}>
              <LoadingButton fullWidth size="large" type="submit" variant="outlined" color="info" loading={isSubmitting}>
                Save
              </LoadingButton>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="status"
                    checked={fromData.status}
                    value={fromData.status}
                    onChange={onChangeFields}
                  />
                }
                label="Status"
              />
            </Grid>

            <Grid mt={2} item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="caption" gutterBottom>
                * Bank Name is required <br />
                * Address is required <br />
                * Only Text - numbers are not allowed in required fields<br />
                * To go back to page click on view list<br />
              </Typography>
            </Grid>

          </Grid>
        </Card>
        </form>
      </Container>
    </Page>
  );
}
