import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Master from './pages/master';
import MasterList from './pages/master/MasterList';

import RegistrarOffice from './pages/master/RegistrarOffice/RegistrarOffice';
import EntryFormRO from './pages/master/RegistrarOffice/EntryForm';
import HandledBy from './pages/master/HandledBy/HandledBy';
import EntryFormHB from './pages/master/HandledBy/EntryForm';
import Del from './pages/master/Del/Del';
import EntryFormD from './pages/master/Del/EntryForm';
import Remarks from './pages/master/Remarks/Remarks';
import EntryFormR from './pages/master/Remarks/EntryForm';
import Bank from './pages/master/Bank/Bank';
import EntryFormB from './pages/master/Bank/EntryForm';
import DSA from './pages/master/Dsa/Dsa';
import EntryFormDSA from './pages/master/Dsa/EntryForm';
import Branch from './pages/master/Branch/Branch';
import EntryFormBR from './pages/master/Branch/EntryForm';
import Fee from './pages/master/Fee/Fee';
import EntryFormFEE from './pages/master/Fee/EntryForm';

import BuilderPayment from './pages/BuilderPayment/BuilderPayment';
import EntryFormBF from './pages/BuilderPayment/EntryForm';

import OptionList from './pages/Option/OptionList';
import PrepareReports from './pages/Option/PrepareReports/PrepareReports';
import EntryFormPR from './pages/Option/PrepareReports/EntryForm';
import DuDupe from './pages/Option/DeDupe/DeDupe';

import DisbursalList from './pages/Disbursal/DisbursalList';
import Registration from './pages/Disbursal/Registration/Registration';
import BT from './pages/Disbursal/BT/BT';

import BillList from './pages/Bill/BillList';
import ProfessionalFee from './pages/Bill/ProfessionalFee/ProfessionalFee';
import Expenses from './pages/Bill/Expenses/Expenses';

import ReportBuilderPayList from './pages/ReportBuilderPay/ReportBuilderPayList';
import ExecutiveWiseMISReport from './pages/ReportBuilderPay/ExecutiveWiseMISReport/ExecutiveWiseMISReport';
import PaymentLedger from './pages/ReportBuilderPay/PaymentLedger/PaymentLedger';

import ReportOpinionList from './pages/ReportOpinion/ReportOpinionList';
import BankWiseMISReport from './pages/ReportOpinion/BankWiseMISReport/BankWiseMISReport';
import StatusWiseMISReport from './pages/ReportOpinion/StatusWiseMISReport/StatusWiseMISReport';
import ExecutiveWiseReport from './pages/ReportOpinion/ExecutiveWiseMISReport/ExecutiveWiseMISReport';
import TypeWiseMISReport from './pages/ReportOpinion/TypeWiseMISReport/TypeWiseMISReport';

import ReportDisbursalList from './pages/ReportDisbursal/ReportDisbursalList';
import RegistrationBank from './pages/ReportDisbursal/RegistrationBank/RegistrationBank';
import RegistrationLedger from './pages/ReportDisbursal/RegistrationLedger/RegistrationLedger';
import LoanBank from './pages/ReportDisbursal/LoanBank/LoanBank';
import LoanLedger from './pages/ReportDisbursal/LoanLedger/LoanLedger';
import PendingReport from './pages/ReportDisbursal/PendingReport/PendingReport';
import LoanTakenOver from './pages/ReportDisbursal/LoanTakenOver/LoanTakenOver';
import LoanRegistrationCaseHandledBy from './pages/ReportDisbursal/LoanRegistrationCaseHandledBy/LoanRegistrationCaseHandledBy';
import CaseByDSAWise from './pages/ReportDisbursal/CaseByDSAWise/CaseByDSAWise';
import CaseByDELWise from './pages/ReportDisbursal/CaseByDELWise/CaseByDELWise';
import CaseByRemarksWise from './pages/ReportDisbursal/CaseByRemarksWise/CaseByRemarksWise';
import DsaList from './pages/ReportDisbursal/DsaList/DsaList';
import ExutivePeformanceSummary from './pages/ReportDisbursal/ExutivePeformanceSummary/ExutivePeformanceSummary';
import AccountSummary from './pages/ReportDisbursal/AccountSummary/AccountSummary';

import MISReportList from './pages/MISReport/MISReportList';
import BankWiseMIS from './pages/MISReport/BankWiseMIS/BankWiseMIS';
import ExecutiveWiseMIS from './pages/MISReport/ExecutiveWiseMIS/ExecutiveWiseMIS';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([


    // App Start
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> }, // Dashboard Main
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },

        // Master Start
        { path: 'master', element: <MasterList /> },  // Master List

        { path: 'master/registrarOffice', element: <RegistrarOffice /> },  // Master RegistrarOffice
        { path: 'master/registrarOffice/newEntry/:data', element: <EntryFormRO /> },  // Master RegistrarOffice

        { path: 'master/handledBy', element: <HandledBy /> },  // Master HandledBy
        { path: 'master/handledBy/newEntry/:data', element: <EntryFormHB /> },  // Master HandledBy

        { path: 'master/del', element: <Del /> },  // Master del
        { path: 'master/del/newEntry/:data', element: <EntryFormD /> },  // Master del

        { path: 'master/remarks', element: <Remarks /> },  // Master remarks
        { path: 'master/remarks/newEntry/:data', element: <EntryFormR /> },  // Master remarks

        { path: 'master/bank', element: <Bank /> },  // Master bank
        { path: 'master/bank/newEntry/:data', element: <EntryFormB /> },  // Master bank

        { path: 'master/dsa', element: <DSA /> },  // Master dsa
        { path: 'master/dsa/newEntry/:data', element: <EntryFormDSA /> },  // Master dsa

        { path: 'master/branch', element: <Branch /> },  // Master branch
        { path: 'master/branch/newEntry/:data', element: <EntryFormBR /> },  // Master branch
        
        { path: 'master/fee', element: <Fee /> },  // Master fee
        { path: 'master/fee/newEntry/:data', element: <EntryFormFEE /> },  // Master fee
        // Master End

        { path: 'builerPayment', element: <BuilderPayment /> }, // BuilderPayment
        { path: 'builerPayment/newEntry/:data', element: <EntryFormBF /> }, // BuilderPayment

        // Option Start
        { path: 'option', element: <OptionList /> },  // Option List

        { path: 'option/prepareReports', element: <PrepareReports /> },  // Option Prepare Reports
        { path: 'option/prepareReports/newEntry/:data', element: <EntryFormPR /> },  // Option Prepare Reports
        { path: 'option/duDupe', element: <DuDupe /> },  // Option DuDupe

        // Option End
        
        // Disbursal Start
        { path: 'disbursal', element: <DisbursalList /> },  // Disbursal List

        { path: 'disbursal/registration/:data', element: <Registration /> },  // Disbursal Registration
        { path: 'disbursal/bt/:data', element: <BT /> },  // Disbursal BT

        // Disbursal End
        
        // Bill Start
        { path: 'bill', element: <BillList /> },  // Bill List

        { path: 'bill/professionalFee/:data', element: <ProfessionalFee /> },  // Bill Registration
        { path: 'bill/expenses/:data', element: <Expenses /> },  // Bill BT
        // Bill End

        // Report Builder Start
        { path: 'reportBuilderPay', element: <ReportBuilderPayList /> },  // Bill List

        { path: 'reportBuilderPay/paymentLedger/:data', element: <PaymentLedger /> },  // Bill Registration
        { path: 'reportBuilderPay/executiveWiseMISReport/:data', element: <ExecutiveWiseMISReport /> },  // Bill BT
        // Report Builder End
        
        // Report (Opinion) Start
        { path: 'reportOpinion', element: <ReportOpinionList /> },  // Bill List

        { path: 'reportOpinion/bankWiseMISReport/:data', element: <BankWiseMISReport /> },  // Bill Registration
        { path: 'reportOpinion/statusWiseMISReport/:data', element: <StatusWiseMISReport /> },  // Bill BT
        { path: 'reportOpinion/executiveWiseMisReport/:data', element: <ExecutiveWiseReport /> },  // Bill BT
        { path: 'reportOpinion/typeWiseMISReport/:data', element: <TypeWiseMISReport /> },  // Bill BT
        // Report (Opinion) End

        // Report (Disbursal) Start
        { path: 'reportDisbursal', element: <ReportDisbursalList /> },  // Bill List

        { path: 'reportDisbursal/registrationBank/:data', element: <RegistrationBank /> },  // Bill Registration
        { path: 'reportDisbursal/registrationLedger/:data', element: <RegistrationLedger /> },  // Bill BT
        { path: 'reportDisbursal/loanBank/:data', element: <LoanBank /> },  // Bill BT
        { path: 'reportDisbursal/loanLedger/:data', element: <LoanLedger /> },  // Bill BT
        { path: 'reportDisbursal/pendingReport/:data', element: <PendingReport /> },  // Bill BT
        { path: 'reportDisbursal/loanTakenOver/:data', element: <LoanTakenOver /> },  // Bill BT
        { path: 'reportDisbursal/loanRegistrationCaseHandledBy/:data', element: <LoanRegistrationCaseHandledBy /> },  // Bill BT
        { path: 'reportDisbursal/caseByDSAWise/:data', element: <CaseByDSAWise /> },  // Bill BT
        { path: 'reportDisbursal/caseByDELWise/:data', element: <CaseByDELWise /> },  // Bill BT
        { path: 'reportDisbursal/caseByRemarksWise/:data', element: <CaseByRemarksWise /> },  // Bill BT
        { path: 'reportDisbursal/dsaList/:data', element: <DsaList /> },  // Bill BT
        { path: 'reportDisbursal/exutivePeformanceSummary/:data', element: <ExutivePeformanceSummary /> },  // Bill BT
        { path: 'reportDisbursal/accountSummary/:data', element: <AccountSummary /> },  // Bill BT
        // Report (Disbursal) End

        // MIS Report Start
        { path: 'misReport', element: <MISReportList /> },  // Bill List

        { path: 'misReport/bankWiseMis/:data', element: <BankWiseMIS /> },  // Bill Registration
        { path: 'misReport/executiveWiseMis/:data', element: <ExecutiveWiseMIS /> },  // Bill BT
        // MIS Report End

        
      ],
    },
    // App End

    // Auth Start
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
    // Auth End
  ]);
}
