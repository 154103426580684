/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import ExecutiveWiseMISReport from "../../ReportBuilderPay/ExecutiveWiseMISReport/ExecutiveWiseMISReport"
// ----------------------------------------------------------------------

export default function ExecutiveWiseReport(props) {
  return (
    <ExecutiveWiseMISReport />
  );
}
