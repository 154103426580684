/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import RegistrationLedger from "../RegistrationLedger/RegistrationLedger"
// ----------------------------------------------------------------------

export default function LoanLedger(props) {
  return (
    <RegistrationLedger name="Loan Ledger" />
  );
}
