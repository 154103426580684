/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import RegistrationBank from "../RegistrationBank/RegistrationBank"
// ----------------------------------------------------------------------

export default function LoanBank(props) {
  return (
    <RegistrationBank name="Loan Bank" />
  );
}
